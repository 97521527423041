/** @jsx jsx */
import { jsx } from "theme-ui"
import { ContentWrapper, Main } from "gatsby-theme-elements"

import Archive from "../components/Archive"
import SEO from "../components/Seo"
import * as vocabURLs from "../assets/icons/vocab"
import { vocabMenu } from "../components/Layout/menus"

const VocabArchive = () => (
  <ContentWrapper maxWidth="100%">
    <SEO title="Vocabulary" />
    <Main>
      <Archive type="Vocabulary" menu={vocabMenu} images={vocabURLs} />
    </Main>
  </ContentWrapper>
)

export default VocabArchive
